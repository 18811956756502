<template>
  <v-sheet width="90%" class="mx-auto mt-5 d-flex align-center flex-column">
    <h4>Not found</h4>

    <v-row class="d-flex justify-center align-center flex-column text-center">
      <v-col>
        <v-btn icon @click="goHome"> <v-icon left>fa-home</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: "record" })
    },
  },
}
</script>
